import * as React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import { graphql } from 'gatsby';
import { Link } from 'gatsby-plugin-react-intl';
import FreeLayout from '../layouts/free-layout/free-layout';

import * as styles from '../styles/pages/not-found.module.scss';

const NotFoundPage = ({ data }) => {
	const intl = useIntl();

	return (
		<FreeLayout
			seo={{
				title: intl.formatMessage({
					id: 'not-found-page-title',
					defaultMessage: 'Page not found',
				}),
				description: data?.api?.seo?.siteDescription,
				allowIndexing: false,
			}}
		>
			<section className={styles.notFoundSection}>
				<h1>
					{intl.formatMessage({
						id: 'not-found-page-title',
						defaultMessage: 'Page not found',
					})}
				</h1>

				<p>
					{intl.formatMessage({
						id: 'not-found-page-description',
						defaultMessage: 'The page you are trying to access could not be found.',
					})}
				</p>

				<Link to="/" className="btn-border-dark">
					{intl.formatMessage({
						id: 'not-found-page-button',
						defaultMessage: 'Back to homepage',
					})}
				</Link>
			</section>
		</FreeLayout>
	);
};

export const query = graphql`
	query($language: String!) {
		api {
			seo: seoSingleton(lang: $language) {
				siteDescription
			}
		}
	}
`;

export default NotFoundPage;
