import * as React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'gatsby-plugin-react-intl';

function Seo({ description, lang, meta, title, allowIndexing, allowFollowing }) {
	const intl = useIntl();
	const { site, intlPlugin } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						author
					}
				}

				intlPlugin: sitePlugin(name: { eq: "gatsby-plugin-react-intl" }) {
					pluginOptions
				}
			}
		`
	);

	const defaultTitle = site.siteMetadata?.title;
	const htmlTitle = title.length > 0 ? `${title} | ${defaultTitle}` : defaultTitle;

	const metaDescription = description || site.siteMetadata?.description;
	const htmlLang = intl?.locale?.length > 0 ? intl?.locale : lang;

	return (
		<Helmet defaultTitle={defaultTitle}>
			<html lang={htmlLang} />

			<meta name="description" content={metaDescription} />

			<meta name="og:type" content="website" />
			<meta name="og:locale" content={htmlLang} />
			<meta name="og:title" content={htmlTitle} />
			<meta name="og:description" content={metaDescription} />

			<meta name="twitter:card" content="summary" />
			<meta name="twitter:creator" content={site.siteMetadata?.author || ''} />
			<meta name="twitter:title" content={htmlTitle} />
			<meta name="twitter:description" content={metaDescription} />

			<meta
				name="robots"
				content={
					(allowIndexing ? 'index, ' : 'noindex, ') + (allowFollowing ? 'follow' : 'nofollow')
				}
			/>

			{meta.map(obj => (
				<meta {...obj} />
			))}

			<title>{htmlTitle}</title>

			<link rel="preconnect" href="https://fonts.gstatic.com" />
			<link
				href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&display=swap"
				rel="stylesheet"
			/>
			<link
				href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&family=Roboto+Slab:wght@300;400;500&display=swap"
				rel="stylesheet"
			/>

			{intlPlugin?.pluginOptions?.languages?.map(lang => (
				<link
					rel="alternate"
					href={`${process.env.GATSBY_BASE_URL}/${lang}/`}
					hrefLang={lang}
					key={lang}
				/>
			))}
		</Helmet>
	);
}

Seo.defaultProps = {
	lang: 'cs',
	meta: [],
	description: '',
	title: '',
	allowIndexing: true,
	allowFollowing: true,
};

Seo.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string,
	allowIndexing: PropTypes.bool,
	allowFollowing: PropTypes.bool,
};

export default Seo;
