import * as React from 'react';
import Seo from '../../components/seo/seo';

const FreeLayout = ({ seo, children }) => {
	return (
		<React.Fragment>
			<Seo {...seo} />

			<main>{children}</main>
		</React.Fragment>
	);
};

export default FreeLayout;
